@if (title() || icon()) {
  <div class="header">
    <div class="title">
      @if (icon(); as icon) {
        <coach-icon class="icon" [icon]="icon" />
      }
      <ng-content select="icon" />
      @if (title(); as title) {
        <span typography="title" variant="4" class="truncate">{{ title }}</span>
      }
    </div>
    <ng-content select="interaction" />
  </div>
}
<ng-content />
