import { Unit, UnitPreference, UnitType } from './unit.types';

const BaseMetricValues = [
  'ball_speed',
  'carry',
  'curve',
  'flat_carry',
  'hang_time',
  'height',
  'landing_angle',
  'launch_angle',
  'performance_hcp',
  'prox_to_hole',
  'shot_distance',
  'strokes_gained',
  'total_distance',
] as const;

const ExtendedMetricValues = [
  'ball_horizontal_launch_angle',
  'ball_launch_angle',
  'ball_spin_axis',
  'ball_spin',
  'club_closure_rate',
  'club_f_angle',
  'club_face_angle',
  'club_head_speed',
  'club_horizontal_path_angle',
  'club_lie',
  'club_loft',
  'club_vertical_path_angle',
] as const;

export type BaseMetric = (typeof BaseMetricValues)[number];
export type ExtendedMetric = (typeof ExtendedMetricValues)[number];

export type Metric = BaseMetric | ExtendedMetric;

export type MetricCategory = 'ball' | 'flight' | 'club' | 'other';

export type EnrichedMetric = {
  id: Metric;
  i18n: string;
  type?: UnitType;
  category?: MetricCategory;
  decimals?: number;
  extended?: boolean;
};

export type EnrichedMetricWithPreference = EnrichedMetric & { preference?: UnitPreference<Unit> };

export const MetricMap: Record<Metric, EnrichedMetric> = {
  ball_speed: { id: 'ball_speed', i18n: 'metrics.ballSpeed', type: 'speed', category: 'ball' },
  carry: { id: 'carry', i18n: 'metrics.carry', type: 'distance', category: 'flight' },
  curve: { id: 'curve', i18n: 'metrics.curve', type: 'distance', category: 'flight' },
  flat_carry: { id: 'flat_carry', i18n: 'metrics.flatCarry', type: 'distance', category: 'flight' },
  hang_time: { id: 'hang_time', i18n: 'metrics.hangTime', type: 'time', category: 'flight' },
  height: { id: 'height', i18n: 'metrics.height', type: 'distance', category: 'flight' },
  landing_angle: { id: 'landing_angle', i18n: 'metrics.landingAngle', type: 'angle', category: 'flight' },
  launch_angle: { id: 'launch_angle', i18n: 'metrics.angle', type: 'angle', category: 'ball' },
  performance_hcp: { id: 'performance_hcp', i18n: 'metrics.performanceHcp' },
  prox_to_hole: { id: 'prox_to_hole', i18n: 'metrics.proxToHole', type: 'distance' },
  shot_distance: { id: 'shot_distance', i18n: 'metrics.shotDistance', type: 'distance' },
  strokes_gained: { id: 'strokes_gained', i18n: 'metrics.strokesGained' },
  total_distance: { id: 'total_distance', i18n: 'metrics.totalDistance', type: 'distance', category: 'flight' },
  ball_horizontal_launch_angle: {
    id: 'ball_horizontal_launch_angle',
    i18n: 'metrics.ballHorizontalLaunchAngle',
    category: 'ball',
    extended: true,
  },
  ball_launch_angle: {
    id: 'ball_launch_angle',
    i18n: 'metrics.ballLaunchAngle',
    type: 'angle',
    category: 'ball',
    extended: true,
  },
  ball_spin_axis: { id: 'ball_spin_axis', i18n: 'metrics.ballSpinAxis', category: 'ball', extended: true },
  ball_spin: { id: 'ball_spin', i18n: 'metrics.ballSpin', category: 'ball', decimals: 0, extended: true },
  club_closure_rate: { id: 'club_closure_rate', i18n: 'metrics.clubClosureRate', category: 'club', extended: true },
  club_f_angle: { id: 'club_f_angle', i18n: 'metrics.clubFAngle', type: 'angle', category: 'club', extended: true },
  club_face_angle: {
    id: 'club_face_angle',
    i18n: 'metrics.clubFaceAngle',
    type: 'angle',
    category: 'club',
    extended: true,
  },
  club_head_speed: {
    id: 'club_head_speed',
    i18n: 'metrics.clubHeadSpeed',
    type: 'speed',
    category: 'club',
    extended: true,
  },
  club_horizontal_path_angle: {
    id: 'club_horizontal_path_angle',
    i18n: 'metrics.clubHorizontalPathAngle',
    type: 'angle',
    category: 'club',
    extended: true,
  },
  club_lie: { id: 'club_lie', i18n: 'metrics.clubLie', category: 'club', extended: true },
  club_loft: { id: 'club_loft', i18n: 'metrics.clubLoft', category: 'club', extended: true },
  club_vertical_path_angle: {
    id: 'club_vertical_path_angle',
    i18n: 'metrics.clubVerticalPathAngle',
    type: 'angle',
    category: 'club',
    extended: true,
  },
};

export const MetricList: Metric[] = [...BaseMetricValues, ...ExtendedMetricValues];
export const EnrichedMetricList: EnrichedMetric[] = MetricList.map(metric => MetricMap[metric]);

export type MetricStats = Record<Metric, MetricCalculation>;

export type MetricCalculation = { mean: number; deviation: number; min: number; max: number };

const CalculationValues = ['mean', 'deviation', 'min', 'max'] as const;

export type Calculation = (typeof CalculationValues)[number];

export const CalculationList: Calculation[] = [...CalculationValues];

export const CalculationMap: Record<Calculation, EnrichedCalculation> = {
  mean: { id: 'mean', i18n: 'metrics.calculation.mean', short: 'Avg' },
  deviation: { id: 'deviation', i18n: 'metrics.calculation.deviation', short: '±' },
  min: { id: 'min', i18n: 'metrics.calculation.min', short: 'Min' },
  max: { id: 'max', i18n: 'metrics.calculation.max', short: 'Max' },
};

export type EnrichedCalculation = {
  id: Calculation;
  i18n: string;
  short: string;
};

export const EnrichedCalculationList: EnrichedCalculation[] = CalculationList.map(metric => CalculationMap[metric]);
