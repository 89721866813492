import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  EnrichedCalculation,
  EnrichedCalculationList,
  EnrichedMetricList,
  EnrichedMetricWithPreference,
} from '@shared/types/metric.types';
import {
  Distance,
  Speed,
  Temperature,
  Unit,
  UnitPreference,
  UnitPreferenceMap,
  UnitType,
} from '@shared/types/unit.types';
import { NotificationPreference, UserSettings } from './settings.types';

type ConvertToUnitProps = {
  value: number | undefined;
  decimals?: number;
  type?: UnitType;
  withPrefix?: boolean;
  unit?: Unit;
  asString?: boolean;
};

const DEFAULT_SETTINGS: UserSettings = {
  units: {
    distance: 'meter',
    temperature: 'celsius',
    speed: 'ms',
  },
  date: {
    short: 'dd/MM/yyyy',
    medium: 'dd/MM/YYYY HH:mm',
    long: 'dd MMM YYYY HH:mm',
  },
  cookies: {
    shown: false,
    consent: false,
  },
  notifications: {
    newAssignment: 'email',
    assignmentDue: 'push',
  },
  metrics: {
    ball_speed: true,
    carry: true,
    curve: true,
    flat_carry: true,
    hang_time: true,
    height: true,
    landing_angle: true,
    launch_angle: true,
    performance_hcp: false,
    prox_to_hole: true,
    shot_distance: false,
    strokes_gained: false,
    total_distance: true,
    ball_horizontal_launch_angle: false,
    ball_launch_angle: false,
    ball_spin_axis: false,
    ball_spin: false,
    club_closure_rate: false,
    club_f_angle: false,
    club_face_angle: false,
    club_head_speed: false,
    club_horizontal_path_angle: false,
    club_lie: false,
    club_loft: false,
    club_vertical_path_angle: false,
  },
  calcs: {
    mean: true,
    deviation: true,
    min: false,
    max: false,
  },
};

const SETTINGS_STORAGE_KEY = 'settings';

const CLUB_CATEGORIES = ['wood', 'iron', 'wedge', 'hybrid'];

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  #transloco = inject(TranslocoService);
  #storedSettings = localStorage.getItem(SETTINGS_STORAGE_KEY);
  #parsedSettings: UserSettings = this.#storedSettings ? JSON.parse(this.#storedSettings) : null;
  settings = signal<UserSettings>({ ...DEFAULT_SETTINGS, ...this.#parsedSettings });

  notificationOptions: {
    value: NotificationPreference;
    i18n: string;
  }[] = [
    { value: 'email', i18n: 'form.email' },
    { value: 'sms', i18n: 'settings.notifications.sms' },
    { value: 'push', i18n: 'settings.notifications.push' },
  ];

  unitOptions: {
    distance: UnitPreference<Distance>[];
    temperature: UnitPreference<Temperature>[];
    speed: UnitPreference<Speed>[];
  } = {
    distance: [
      { type: 'distance', value: 'meter', i18n: 'settings.distance.meter', short: 'm' },
      { type: 'distance', value: 'yard', i18n: 'settings.distance.yard', short: 'yd' },
      { type: 'distance', value: 'yardFeetInches', i18n: 'settings.distance.yardFeetInches', short: 'yd/ft/in' },
    ],
    temperature: [
      { type: 'temperature', value: 'celsius', i18n: 'settings.temperature.celsius', short: '°C' },
      { type: 'temperature', value: 'fahrenheit', i18n: 'settings.temperature.fahrenheit', short: '°F' },
    ],
    speed: [
      { type: 'speed', value: 'ms', i18n: 'settings.speed.metersPerSecond', short: 'm/s' },
      { type: 'speed', value: 'kmh', i18n: 'settings.speed.kilometersPerHour', short: 'km/h' },
      { type: 'speed', value: 'mph', i18n: 'settings.speed.milesPerHour', short: 'mph' },
    ],
  };

  #conversion = {
    distance: {
      meter: 1, // Default
      yard: 1.09361,
      yardFeetInches: 3.28084,
    },
    speed: {
      ms: 1, // Default
      kmh: 3.6,
      mph: 2.23694,
    },
    temperature: {
      celsius: 1, // Default
      fahrenheit: 1.8,
    },
  } as const;

  visibleMetrics = computed<EnrichedMetricWithPreference[]>(() =>
    EnrichedMetricList.filter(metric => this.settings().metrics[metric.id]).map(metric => ({
      ...metric,
      preference: this.getPreferenceForType(metric.type),
    }))
  );

  visibleCalculations = computed<EnrichedCalculation[]>(() =>
    EnrichedCalculationList.filter(calc => this.settings().calcs[calc.id])
  );

  constructor() {
    effect(() => {
      localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(this.settings()));
    });
  }

  updatePreference<K extends keyof UserSettings>(
    group: K,
    setting: keyof UserSettings[K],
    choice: UserSettings[K][keyof UserSettings[K]]
  ) {
    this.settings.update(settings => ({
      ...settings,
      [group]: {
        ...settings[group],
        [setting]: choice,
      },
    }));
  }

  getPreferenceForType(type?: UnitType) {
    if (type === 'distance' || type === 'speed' || type === 'temperature') {
      return UnitPreferenceMap[this.settings().units[type]];
    }
    if (type === 'time') {
      return UnitPreferenceMap.seconds;
    }
    if (type === 'angle') {
      return UnitPreferenceMap.degrees;
    }
    if (type === 'date') {
      return UnitPreferenceMap.days;
    }
    return;
  }

  filterExtendedMetrics(metrics: EnrichedMetricWithPreference[], showExtendedMetrics: boolean) {
    return metrics.filter(metric => metric.extended === undefined || metric.extended === showExtendedMetrics);
  }

  convertToUnit({
    value,
    decimals = 2,
    type,
    withPrefix = false,
    unit,
    asString = true,
  }: ConvertToUnitProps): number | string {
    if (!value && value !== 0) {
      return '';
    }
    let result = value;
    if (!type) {
      return `${result.toFixed(decimals)}`;
    }
    const u = unit ? UnitPreferenceMap[unit] : this.getPreferenceForType(type);
    if (!u) {
      return `${result.toFixed(decimals)}`;
    }
    const suffix = withPrefix ? ` ${u?.short}` : '';
    if (type === 'distance' || type === 'speed' || type === 'temperature') {
      const c = this.#conversion[type];
      const scalar = c[u.value as keyof typeof c];
      result = scalar ? value * scalar : value;
      if (u.value === 'fahrenheit') {
        result += 32;
      }
    }

    if (!asString) {
      return Number(result.toFixed(decimals));
    }
    return `${result.toFixed(decimals)}${suffix}`;
  }

  translateClub(type?: string, category?: string): string {
    if (!type || (category && !CLUB_CATEGORIES.includes(category))) {
      return `Unsupported club / ${type} / ${category}`;
    }

    const n = parseInt(type[0], 10);
    const number: number | undefined = isNaN(n) ? undefined : n;

    if (category === 'wedge') {
      const i18n = `club.wedge.${type.toLocaleLowerCase()}`;
      return this.#transloco.translate(i18n);
    }

    if (category === 'wood' && type === 'DR') {
      return this.#transloco.translate('club.driver');
    }

    return this.#transloco.translate(`club.${category}`, { count: number });
  }
}
